import './App.css';

import React, { StrictMode, lazy, Suspense, useEffect } from 'react';
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import _ from 'lodash';
import Cookies from 'universal-cookie';
import Header from './components/common/Header';
import Footer from './components/common/Footer';
import { useDispatch, useSelector } from 'react-redux';
import { UserDetailsAction } from './modules/actions/User/getUserDetails.js';
import { CartDetailsAction } from './modules/actions/User/getCartDetails.js';
import LoadingSpinner from '../src/utils/LoadingSpinner';
const cookies = new Cookies();
const Home = lazy(() => import('./pages/HomePage'));
const BookExam = lazy(() => import('./pages/BookExamPage'));
const OnlineMockExam = lazy(() => import('./pages/OnlineMockExamPage'));
const Login = lazy(() => import('./pages/LoginPage'));
const Register = lazy(() => import('./pages/RegisterPage'));
const UserDashboard = lazy(() => import('./pages/UserDashboard'));
const Profile = lazy(() => import('./pages/Profile'));
const Checkout = lazy(() => import('./pages/Checkout'));
const Cart = lazy(() => import('./pages/Cart'));
const Tuitions = lazy(() => import('./pages/Tuitions'));
const TuitionsDetails = lazy(() => import('./pages/TuitionsDetails'));
const BookExamPopup = lazy(() => import('./pages/BookExamPopup'));
const TermsAndCondition = lazy(() => import('./pages/TermsAndCondition'));
const PracticePaper = lazy(() => import('./pages/PracticePaper'));
const PastPaper = lazy(() => import('./pages/PastPaper'));
const Faq = lazy(() => import('./pages/Faq'));
const AboutUs = lazy(() => import('./pages/AboutUs'));
const Testimonial = lazy(() => import('./pages/Testimonial'));
const WhyOurMocks = lazy(() => import('./pages/WhyOurMocks'));
const MyMocks = lazy(() => import('./pages/MyMocks'));
const MyPrecticePaper = lazy(() => import('./pages/MyPrecticePaper'));
const MyPastPaper = lazy(() => import('./pages/MyPastPaper'));
const MyReport = lazy(() => import('./pages/MyReport'));
const QuesAns = lazy(() => import('./pages/QuesAns'));
const TrigonometricFunctions = lazy(() => import('./pages/TrigonometricFunctions'));
const ExamDetails = lazy(() => import('./pages/ExamDetails'));
const TakeMockExam = lazy(() => import('./pages/TakeMockExam'));
const TakePracticePaperExam = lazy(() => import('./pages/TakePracticePaper'));
const PrecticePaperDetails = lazy(() => import('./pages/PrecticePaperDetails'));
const PastPaperDetails = lazy(() => import('./pages/PastPaperDetails'));
const PackageDetails = lazy(() => import('./pages/PackageDetails'));
const BoardDetails = lazy(() => import('./pages/BoardDetails'));
const Package = lazy(() => import('./pages/Package'));
const MCQ = lazy(() => import('./pages/MCQ'));
const MockProgressReport = lazy(() => import('./pages/MockProgressPage'));
const PracticeProgressReport = lazy(() => import('./pages/PracticeProgressPage'));
const ForgotPass = lazy(() => import('./pages/ForgotPass'));
const MyPuchases = lazy(() => import('./pages/MyPuchases'));
const { excludesHeader } = require('./disabledHeaderRoutes');

function App(props) {

  const dispatch = useDispatch();

  const location = useLocation();

  const userDetailsData = useSelector((state) => state.UserDetailsReducer);

  const isLoggedIn = _.isEmpty(userDetailsData) ? (cookies.get('token') ? true : false) : true;

  useEffect(() => {
    const loadScriptByURL = (url) => {
      let script = document.createElement("script");
      script.type = "text/javascript";
      script.src = url;
      script.async = true
      document.head.appendChild(script);
    }

    dispatch(UserDetailsAction());
    dispatch(CartDetailsAction());
    if (process.env.NODE_ENV === "production" && window.location.href.includes("https://youace.co.in")) {
      loadScriptByURL('/js/google-analytics.js')
    }
  }, [dispatch]);

  const currentPath = location.pathname.split('/');
  currentPath.shift();

  const isFullPageDisabled = excludesHeader.indexOf('/' + currentPath[0]) !== -1;

  return (
    <StrictMode>
      <LoadingSpinner />
      {!isFullPageDisabled && <Header />}
      {/* <div className="minHeightDiv"></div> */}
      <div id='main-root-div' className='main-root-div'>
        <Suspense fallback={<></>}>
          <div id="alertid" className="alert_wrapper" />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/book-exam-online" element={<BookExam state={{ status: 'online' }} />} />
            <Route path="/book-exam-offline" element={<BookExam state={{ status: 'offline' }} />} />
            <Route path="/book-exam-details" element={<ExamDetails />} />
            <Route path="/practice-paper-details" element={<PrecticePaperDetails />} />
            <Route path="/mock-exam" element={<OnlineMockExam />} />
            <Route path="/home" element={<Home />} />
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/tutions" element={<Tuitions />} />
            <Route path="/terms-and-conditions" element={<TermsAndCondition />} />
            <Route path="/tutions-details" element={<TuitionsDetails />} />
            <Route path="/product-details" element={<PackageDetails />} />
            <Route path="/past-paper-details" element={<PastPaperDetails />} />
            <Route path="/board-details" element={<BoardDetails />} />
            <Route path="/product" element={<Package />} />
            <Route path="/mcq" element={<MCQ />} />
            <Route path="/book-exam-online-popup" element={<BookExamPopup state={{ status: 'online' }} />} />
            <Route path="/book-exam-offline-popup" element={<BookExamPopup state={{ status: 'offline' }} />} />
            <Route path="/practice-paper" element={<PracticePaper />} />
            <Route path="/past-paper" element={<PastPaper />} />
            <Route path="/faq" element={<Faq />} />
            <Route path="/about-us" element={<AboutUs />} />
            <Route path="/testimonial" element={<Testimonial />} />
            <Route path="/why-our-mocks" element={<WhyOurMocks />} />
            <Route path="/trigonometric-functions" element={<TrigonometricFunctions />} />

            <Route path="/mocks-practice-paper-package" element={<Package />} />
            <Route path="/past-paper-practice-paper-package" element={<Package />} />
            <Route path="/jumbo-package" element={<Package />} />
            <Route path="/password-reset/:id" element={<ForgotPass />} />
            <Route path="/cart" element={<Cart />} />

            <Route path='*' element={<Navigate to='/' />} />


            <Route path="/my-purchase"
              element={<RequireAuth isLoggedIn={isLoggedIn} redirectTo="/login" oldUrl="/my-purchase">
                <MyPuchases />
              </RequireAuth>} />


            <Route path="/take-mock-exam/:mockQuizId"
              element={<RequireAuth isLoggedIn={isLoggedIn} redirectTo="/login" oldUrl="my-mocks">
                <TakeMockExam />
              </RequireAuth>} />

            <Route path="/resume-mock-exam/:mockQuizId"
              element={<RequireAuth isLoggedIn={isLoggedIn} redirectTo="/login" oldUrl="my-mocks">
                <TakeMockExam />
              </RequireAuth>} />

            <Route path="/take-practice-paper/:mockQuizId"
              element={<RequireAuth isLoggedIn={isLoggedIn} redirectTo="/login" oldUrl="my-mocks">
                <TakePracticePaperExam />
              </RequireAuth>} />

            <Route path="/resume-practice-paper/:mockQuizId"
              element={<RequireAuth isLoggedIn={isLoggedIn} redirectTo="/login" oldUrl="my-mocks">
                <TakePracticePaperExam />
              </RequireAuth>} />

            <Route path="/mock-progress-report/:responseId"
              element={<RequireAuth isLoggedIn={isLoggedIn} redirectTo="/login" oldUrl="my-report">
                <MockProgressReport />
              </RequireAuth>} />

            <Route path="/practice-progress-report/:responseId"
              element={<RequireAuth isLoggedIn={isLoggedIn} redirectTo="/login" oldUrl="my-report">
                <PracticeProgressReport />
              </RequireAuth>} />

            <Route
              path="/profile"
              element={
                <RequireAuth isLoggedIn={isLoggedIn} redirectTo="/login" oldUrl="profile">
                  <Profile />
                </RequireAuth>
              }
            />

            <Route path="/checkout"
              element={<RequireAuth isLoggedIn={isLoggedIn} redirectTo="/login" oldUrl="checkout">
                <Checkout />
              </RequireAuth>} />

            <Route path="/checkout/:id"
              element={<RequireAuth isLoggedIn={isLoggedIn} redirectTo="/login" oldUrl="checkout">
                <Checkout />
              </RequireAuth>} />

            <Route path="/dashboard"
              element={<RequireAuth isLoggedIn={isLoggedIn} redirectTo="/login" oldUrl="dashboard">
                <UserDashboard />
              </RequireAuth>} />

            <Route path="/my-mocks"
              element={<RequireAuth isLoggedIn={isLoggedIn} redirectTo="/login" oldUrl="my-mocks">
                <MyMocks />
              </RequireAuth>} />

            <Route path="/my-practice-paper"
              element={<RequireAuth isLoggedIn={isLoggedIn} redirectTo="/login" oldUrl="my-mocks">
                <MyPrecticePaper />
              </RequireAuth>} />

            <Route path="/my-past-paper"
              element={<RequireAuth isLoggedIn={isLoggedIn} redirectTo="/login" oldUrl="my-mocks">
                <MyPastPaper />
              </RequireAuth>} />

            <Route path="/my-report"
              element={<RequireAuth isLoggedIn={isLoggedIn} redirectTo="/login" oldUrl="my-report">
                <MyReport />
              </RequireAuth>} />

            <Route path="/questions-answers"
              element={<RequireAuth isLoggedIn={isLoggedIn} redirectTo="/login" oldUrl="questions-answers">
                <QuesAns />
              </RequireAuth>} />

          </Routes>

        </Suspense>
      </div>
      {!isFullPageDisabled && <Footer />}
      <div className="gototop">
        <img src="/images/top-arrow.svg" alt="" />
      </div>
    </StrictMode>
  );
}

function RequireAuth({ children, redirectTo, isLoggedIn, oldUrl }) {
  return cookies.get('token') ? children : <Navigate to={redirectTo} state={{ referer: oldUrl }} />;
}

export default App;
