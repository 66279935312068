import Axios from 'axios';
import { ShowLoaderAction, RemoveLoaderAction } from '../modules/actions/Loader/LoaderShowAction';
import store from '../modules/store/store';

import Cookies from 'universal-cookie';

const cookies = new Cookies();

export const baseURL = process.env.REACT_APP_API_URL || 'https://staging-api.youace.co.in';

const axios = Axios.create({
    baseURL: baseURL,
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
    },
});

export const get = async (endpoint, loader = false) => {

    let config = {}
    if (cookies.get('token')) {
        config = {
            headers: {
                'authorization': cookies.get('token')
            }
        }
    }

    store && loader && store.dispatch(ShowLoaderAction(1));
    const res = await axios.get(endpoint, config);
    store && store.dispatch(RemoveLoaderAction(1));
    if (res && res.data && res.data.authRequired) {
        cookies.remove('token');
    }
    return res.data;
};

export const post = async (endpoint, data, loader = false) => {

    let config = {
        headers: {
            'Authorization': cookies.get('token') || ''
        }
    }

    store && loader && store.dispatch(ShowLoaderAction(1));
    const res = await axios.post(endpoint, data, config);
    store && store.dispatch(RemoveLoaderAction(1));
    if (res && res.data && res.data.authRequired) {
        cookies.remove('token');
    }
    return res.data;
};
