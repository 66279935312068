import React, { StrictMode } from 'react';

function Footer() {

    return (
        <StrictMode>
            <footer id="footer" className="wow fadeInUp footer--pin">
                <div className="copyrightBlock wow fadeInUp">
                    <div className="container">
                        Copyright youace. All Right Reserved.
                    </div>
                </div>
            </footer>
        </StrictMode>
    )
}

export default Footer;